.App {
  text-align: center;
  background: #f9f9f9;
  width: calc(100% - 32px);
  overflow: hidden;
  /* height: 100%; */
  height: calc(100vh - 32px);
  padding: 16px;
  word-break: keep-all;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* bg-full */
.bg-full {
  width: 100%;
  height: calc(100vh - 32px);
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bg-full img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* scroll */
::-webkit-scrollbar {
  -webkit-appearance: none;
  background: #f3f3f3;
  height: 8px;
  width: 8px;
}
::-webkit-scrollbar-track {
  background: #f3f3f3;
  border-radius: 8px;
}
::-webkit-scrollbar-thumb {
  background-color: #dbdbdf;
  border-radius: 8px;
}
::-webkit-scrollbar-button,
::-moz-scrollbar-button {
  display: none;
  width: 0;
  height: 0;
}
.overflow-auto,
.table-wrap,
.ant-table-content,
.ant-spin-container,
.ant-table-body,
.ant-menu-inline.ant-menu-root,
.payment-wrap,
.body,
html {
  scrollbar-color: #dbdbdf #f3f3f3;
  scrollbar-width: thin;
  scroll-behavior: smooth;
}
.scroll-hidden {
  overflow: hidden !important;
}
.scroll-x-auto {
  overflow-x: auto !important;
}
.scroll-y-auto {
  overflow-y: auto !important;
}
.scroll-auto {
  overflow: auto !important;
}

/* 초기화 */
ul,
li {
  list-style: none;
}

/* 공통 */
.flex-row {
  display: flex;
  align-items: center;
}
.flex-row-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-nowrap {
  flex-wrap: nowrap;
}
.flex-1 {
  flex: 1;
}
.flex-items-center {
  align-items: center;
}
.flex-items-start {
  align-items: flex-start;
}
.flex-items-end {
  align-items: flex-end;
}
.flex-items-stretch {
  align-items: stretch;
}
.flex-justify-center {
  justify-content: center;
}
.flex-justify-start {
  justify-content: flex-start;
}
.flex-justify-end {
  justify-content: flex-end;
}
.flex-justify-between {
  justify-content: space-between;
}
.flex-justify-around {
  justify-content: space-around;
}
.flex-justify-evenly {
  justify-content: space-evenly;
}

.grid-col-10 {
  display: grid;
  grid-template-columns: repeat(10, minmax(0, 1fr));
  gap: 1rem;
}
.grid-col-9 {
  display: grid;
  grid-template-columns: repeat(9, minmax(0, 1fr));
  gap: 1rem;
}
.grid-col-8 {
  display: grid;
  grid-template-columns: repeat(8, minmax(0, 1fr));
  gap: 1rem;
}
.grid-col-7 {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  gap: 1rem;
}
.grid-col-6 {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  gap: 1rem;
}
.grid-col-5 {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 1rem;
}
.grid-col-4 {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 1rem;
}
.grid-col-3 {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 1rem;
}
.grid-col-2 {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
}
.grid-col-1 {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1rem;
}

.grid-col-12 {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1rem;
  width: 100%;
}
.grid-span-0 {
  grid-column: span 0;
}
.grid-span-1 {
  grid-column: span 1;
}
.grid-span-2 {
  grid-column: span 2;
}
.grid-span-3 {
  grid-column: span 3;
}
.grid-span-4 {
  grid-column: span 4;
}
.grid-span-5 {
  grid-column: span 5;
}
.grid-span-6 {
  grid-column: span 6;
}
.grid-span-7 {
  grid-column: span 7;
}
.grid-span-8 {
  grid-column: span 8;
}
.grid-span-9 {
  grid-column: span 9;
}
.grid-span-10 {
  grid-column: span 10;
}
.grid-span-11 {
  grid-column: span 11;
}
.grid-span-12 {
  grid-column: span 12;
}

/* w / h / m / p  공통 */
.w-full {
  width: 100%;
}
.w-fit {
  width: fit-content;
}
.w-90 {
  width: 90%;
}
.w-80 {
  width: 80%;
}
.w-70 {
  width: 70%;
}
.w-60 {
  width: 60%;
}
.w-50 {
  width: 50%;
}
.w-40 {
  width: 40%;
}
.w-30 {
  width: 30%;
}
.w-20 {
  width: 20%;
}
.w-10 {
  width: 10%;
}
.w-0 {
  width: 0;
}

.h-full {
  height: 100%;
}

/* margin */
.m-0 {
  margin: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.mtb-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.mlr-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.m-auto {
  margin: 0 auto;
}
.mt-auto {
  margin-top: auto !important;
}
.mb-auto {
  margin-bottom: auto !important;
}
.ml-auto {
  margin-left: auto !important;
}
.mr-auto {
  margin-right: auto !important;
}

.m-4 {
  margin: 0.25rem;
}
.mt-4 {
  margin-top: 0.25rem;
}
.mb-4 {
  margin-bottom: 0.25rem;
}
.ml-4 {
  margin-left: 0.25rem;
}
.mr-4 {
  margin-right: 0.25rem;
}
.mtb-4 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.mlr-4 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.m-6 {
  margin: 0.375rem;
}
.mt-6 {
  margin-top: 0.375rem;
}
.mb-6 {
  margin-bottom: 0.375rem;
}
.ml-6 {
  margin-left: 0.375rem;
}
.mr-6 {
  margin-right: 0.375rem;
}
.mtb-6 {
  margin-top: 0.375rem;
  margin-bottom: 0.375rem;
}
.mlr-6 {
  margin-left: 0.375rem;
  margin-right: 0.375rem;
}

.m-8 {
  margin: 0.5rem;
}
.mt-8 {
  margin-top: 0.5rem;
}
.mb-8 {
  margin-bottom: 0.5rem;
}
.ml-8 {
  margin-left: 0.5rem;
}
.mr-8 {
  margin-right: 0.5rem;
}
.mtb-8 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.mlr-8 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.m-10 {
  margin: 0.625rem;
}
.mt-10 {
  margin-top: 0.625rem;
}
.mb-10 {
  margin-bottom: 0.625rem;
}
.ml-10 {
  margin-left: 0.625rem;
}
.mr-10 {
  margin-right: 0.625rem;
}
.mtb-10 {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}
.mlr-10 {
  margin-left: 0.625rem;
  margin-right: 0.625rem;
}

.m-16 {
  margin: 1rem;
}
.mt-16 {
  margin-top: 1rem;
}
.mb-16 {
  margin-bottom: 1rem;
}
.ml-16 {
  margin-left: 1rem;
}
.mr-16 {
  margin-right: 1rem;
}
.mtb-16 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.mlr-16 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.m-24 {
  margin: 1.5rem;
}
.mt-24 {
  margin-top: 1.5rem;
}
.mb-24 {
  margin-bottom: 1.5rem;
}
.ml-24 {
  margin-left: 1.5rem;
}
.mr-24 {
  margin-right: 1.5rem;
}
.mtb-24 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.mlr-24 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.m-32 {
  margin: 2rem;
}
.mt-32 {
  margin-top: 2rem;
}
.mb-32 {
  margin-bottom: 2rem;
}
.ml-32 {
  margin-left: 2rem;
}
.mr-32 {
  margin-right: 2rem;
}
.mtb-32 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.mlr-32 {
  margin-left: 2rem;
  margin-right: 2rem;
}

/* padding */
.p-0 {
  padding: 0 !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.ptb-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.plr-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.p-4 {
  padding: 0.25rem;
}
.pt-4 {
  padding-top: 0.25rem;
}
.pb-4 {
  padding-bottom: 0.25rem;
}
.pl-4 {
  padding-left: 0.25rem;
}
.pr-4 {
  padding-right: 0.25rem;
}
.ptb-4 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.plr-4 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.p-8 {
  padding: 0.5rem;
}
.pt-8 {
  padding-top: 0.5rem;
}
.pb-8 {
  padding-bottom: 0.5rem;
}
.pl-8 {
  padding-left: 0.5rem;
}
.pr-8 {
  padding-right: 0.5rem;
}
.ptb-8 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.plr-8 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.p-10 {
  padding: 0.625rem;
}
.pt-10 {
  padding-top: 0.625rem;
}
.pb-10 {
  padding-bottom: 0.625rem;
}
.pl-10 {
  padding-left: 0.625rem;
}
.pr-10 {
  padding-right: 0.625rem !important;
}
.ptb-10 {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}
.plr-10 {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.p-16 {
  padding: 1rem;
}
.pt-16 {
  padding-top: 1rem;
}
.pb-16 {
  padding-bottom: 1rem;
}
.pl-16 {
  padding-left: 1rem;
}
.pr-16 {
  padding-right: 1rem;
}
.ptb-16 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.plr-16 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.p-18 {
  padding: 1.125rem;
}
.pt-18 {
  padding-top: 1.125rem;
}
.pb-18 {
  padding-bottom: 1.125rem;
}
.pl-18 {
  padding-left: 1.125rem;
}
.pr-18 {
  padding-right: 1.125rem;
}
.ptb-18 {
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
}
.plr-18 {
  padding-left: 1.125rem;
  padding-right: 1.125rem;
}

.p-24 {
  padding: 1.5rem;
}
.pt-24 {
  padding-top: 1.5rem;
}
.pb-24 {
  padding-bottom: 1.5rem;
}
.pl-24 {
  padding-left: 1.5rem;
}
.pr-24 {
  padding-right: 1.5rem;
}
.ptb-24 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.plr-24 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.p-32 {
  padding: 2rem;
}
.pt-32 {
  padding-top: 2rem;
}
.pb-32 {
  padding-bottom: 2rem;
}
.pl-32 {
  padding-left: 2rem;
}
.pr-32 {
  padding-right: 2rem;
}
.ptb-32 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.plr-32 {
  padding-left: 2rem;
  padding-right: 2rem;
}

/* gap */
.gap-0 {
  gap: 0 !important;
}
.gap-2 {
  gap: 0.125rem;
}
.gap-4 {
  gap: 0.25rem;
}
.gap-6 {
  gap: 0.375rem;
}
.gap-8 {
  gap: 0.5rem;
}
.gap-10 {
  gap: 0.625rem;
}
.gap-16 {
  gap: 1rem;
}
.gap-20 {
  gap: 1.25rem;
}
.gap-24 {
  gap: 1.5rem;
}

/* color */
.text-red {
  color: red;
}
.text-purple {
  color: #7c3ef2;
}
.text-gray-100 {
  color: rgba(0, 0, 0, 0.1);
}
.text-gray-200 {
  color: rgba(0, 0, 0, 0.2);
}
.text-gray-300 {
  color: rgba(0, 0, 0, 0.3);
}
.text-gray-400 {
  color: rgba(0, 0, 0, 0.4);
}
.text-gray-500 {
  color: rgba(0, 0, 0, 0.5);
}
.text-gray-600 {
  color: rgba(0, 0, 0, 0.6);
}
.text-gray-700 {
  color: rgba(0, 0, 0, 0.7);
}
.text-gray-800 {
  color: rgba(0, 0, 0, 0.8);
}
.text-gray-900 {
  color: rgba(0, 0, 0, 0.9);
}
.text-black {
  color: rgba(0, 0, 0, 1);
}

/* text-decoration */
.text-underline {
  text-decoration: underline;
  text-underline-offset: 0.25rem;
}

/* display */
.hide {
  display: none;
}
.show {
  display: block;
}

/* ======================================================= */
/* 예시(추후에 삭제함) */
.items {
  width: 100%;
  height: 100px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 8px;
  cursor: pointer;
}
.key {
  color: #aaa;
}
.border {
  border: 1px solid #eee;
}

/* ======================================================= */
/* 반응형 */
@media screen and (max-width: 1919px) {
  .grid-col-10 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
}
@media screen and (max-width: 1441px) {
  .grid-col-10 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
}

@media screen and (max-width: 1299px) {
  .grid-col-10 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}

@media screen and (max-width: 1190px) {
  .grid-col-10 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

@media screen and (max-width: 1040px) {
  .grid-col-10 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media screen and (max-width: 870px) {
  *,
  html,
  body {
    /* font-size: 0.875rem !important; */
    font-size: 15px !important;
  }
  .body-wrap {
    width: calc(100% - 104px) !important;
  }
  h2 {
    font-size: 1.5rem !important;
  }
  .btn-menu-fold {
    display: none;
  }
  .clock-time {
    font-size: 1.2rem !important;
  }
  .clock-area {
    width: 100px !important;
  }
}

@media screen and (max-width: 680px) {
  .body-wrap {
    width: 100% !important;
  }
  .menu-wrap {
    display: none;
  }
  .menu-mobile {
    display: block;
  }
  .menu-mobile-open .ant-drawer-body {
    padding: 0;
  }
  .menu-mobile-open .menu-wrap {
    display: block;
  }
  .menu-mobile-open .ant-menu-inline.ant-menu-root {
    height: calc(100vh - 57px);
  }
}

@media screen and (max-width: 600px) {
  .grid-col-10 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .clock-area {
    display: none !important;
  }
  .ant-modal {
    top: 3.6rem !important;
  }
}

@media screen and (max-width: 572px) {
  footer {
    flex-wrap: wrap;
    justify-content: center !important;
    background: #f9f9f9;
    height: fit-content !important;
  }
  footer .commu-status {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: center;
  }
  footer .commu-status .ant-tag {
    margin-inline-end: 0;
  }
  footer a.tel {
    margin-left: 0 !important;
  }
  .body {
    height: calc(100vh - 224px) !important;
  }
}

/* @media screen and (max-width: 500px) { */
@media screen and (max-width: 600px) {
  .grid-col-2 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .grid-col-12 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  [class*="grid-span-"] {
    grid-column: span 12 !important;
  }
}

@media screen and (max-width: 460px) {
  .grid-col-10 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .App {
    padding: 10px;
    width: calc(100% - 20px);
  }
  .room-wrap {
    padding: 1rem !important;
  }
  .header {
    height: 100% !important;
  }
  .body {
    height: calc(100vh - 245px) !important;
  }
}

@media screen and (max-width: 340px) {
  .grid-col-10 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
